import { apiSlice } from '../../app/api/apiSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<any, void>({
      query: () => '/user/profile',
      transformResponse(response: any) {
        return response?.response;
      },
    }),
    getUserDetails: build.query<any, number>({
      query: (id) => `user/${id}`,
      transformResponse(response: any) {
        return response?.response;
      },
    }),
  }),
});

export const { useGetUserQuery, useGetUserDetailsQuery } = userApiSlice;
