import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string().required('Company name is required'),
  category_id: yup.string().required('Business sector is required'),
  type_of_company: yup.string().required('Type of company is required'),
  office_location: yup.string().required('Office location is required'),
  office_phone: yup.string().required('Office phone is required'),
  office_email: yup.string().email('Enter a valid email address').required('Email is required'),
  business_history: yup.string().required('Brief history is required'),
  company_website: yup.string(),
  linkedin: yup.string(),
  twitter: yup.string(),
  facebook: yup.string(),
});
