import logo from '../assets/logo.png';
import googleIcon from '../assets/google.svg';
import linkedInIcon from '../assets/linkedin.svg';
import nairobi from '../assets/bf_background.png';
import mkWhite from '../assets/mk_logo_white.svg';
import mkGreen from '../assets/mk-green-logo.svg';
import africaLight from '../assets/africa_light.svg';

const images = {
  logo,
  googleIcon,
  linkedInIcon,
  nairobi,
  mkGreen,
  mkWhite,
  africaLight,
};

export default images;
