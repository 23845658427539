import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials: { email: string; password: string }) => {
        const data = new FormData();
        data.append('email', credentials.email);
        data.append('password', credentials.password);
        return {
          url: '/auth',
          method: 'POST',
          body: data,
        };
      },
    }),
    registerUser: build.mutation({
      query: (credentials) => {
        const data = new FormData();
        for (const credentialsKey in credentials) {
          data.append(credentialsKey, credentials[credentialsKey]);
        }
        return {
          url: '/signup',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useRegisterUserMutation, useLoginMutation } = authApiSlice;
