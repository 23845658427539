import { apiSlice } from '../../app/api/apiSlice';

const businessSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createBusiness: build.mutation({
      query: (payload: any) => {
        const data = new FormData();
        for (const payloadKey in payload) {
          data.append(payloadKey, payload[payloadKey]);
        }
        return {
          url: '/business/create',
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useCreateBusinessMutation } = businessSlice;
