import styled from 'styled-components';

export const Wrapper = styled.main`
  background-image: url('https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/waitlist%2FTuuungane%402x%20(3).png?alt=media&token=6356915a-9d12-480e-a2ae-6aaba9b7004b');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 1.6rem 2rem;
  height: 100vh;
`;

export const Logo = styled.img`
  height: auto;
  width: 25rem;
`;
