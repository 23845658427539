import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Register from './pages/Auth/Register/Register';
import pageRoutes from './constants/pageRoutes';
import AuthLayout from './pages/Auth/AuthLayout';
import WelcomeLayout from './pages/WelcomeLayout';
import WaitList from './pages/WaitList/WaitList';
import BusinessProfile from './pages/Auth/Register/BusinessProfile/BusinessProfile';
import DashboardLayout from './pages/DashboardLayout';
import SignIn from './pages/Auth/SignIn/SignIn';

const App = () => (
  <div className="App" id={'app'}>
    <Routes>
      <Route path={'/'} element={<WelcomeLayout />}>
        <Route index element={<Navigate to={pageRoutes.SIGN_UP} />} />
        <Route element={<AuthLayout />}>
          <Route path={pageRoutes.SIGN_IN} element={<SignIn />} />
          <Route path={pageRoutes.SIGN_UP} element={<Register />} />
        </Route>
        <Route path={pageRoutes.DASHBOARD} element={<DashboardLayout />}>
          <Route path={pageRoutes.PROFILE} element={<BusinessProfile />} />
          <Route path={pageRoutes.WAIT_LIST} element={<WaitList />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </div>
);

export default App;
