import React from 'react';
import { Logo, Wrapper } from './styles';
import { openModal } from '../../features/modal/modalSlice';
import { MODAL_TYPES } from '../../modules/ModalWindow/modalTypes';
import { useAppDispatch } from '../../app/hooks';
import images from '../../constants';
import Modal from '../../modules/ModalWindow/Modal';
import { useGetUserQuery } from '../../features/user/userApiSlice';

const WaitList = () => {
  const dispatch = useAppDispatch();
  dispatch(openModal({ modalType: MODAL_TYPES.WAIT_LIST_MODAL }));
  const { data: user } = useGetUserQuery();
  const username = user.name.split(' ').slice(0, -1).join(' ');
  return (
    <>
      <Modal name={username} />
      <Wrapper>
        <Logo src={images.logo} alt="Melanin kapital logo" />
      </Wrapper>
    </>
  );
};

export default WaitList;
