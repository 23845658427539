import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, IContainer, Input, Socials, TArea, Wrap } from './styles';
import { ImFacebook2, ImLinkedin } from 'react-icons/im';
import { BsArrowRight, BsTwitter } from 'react-icons/bs';
import Select from 'react-select';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './BusinessProfileFill.module.scss';
import images from '../../../../constants';
import { AiOutlineCheck } from 'react-icons/ai';
import { Profile } from '../../../../types/user';
import { useGetUserDetailsQuery, useGetUserQuery } from '../../../../features/user/userApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../../styles/loaders.css';
import { useCreateBusinessMutation } from '../../../../features/business/businessApiSlice';
import { motion } from 'framer-motion';
import { Navigate, useNavigate } from 'react-router-dom';
import pageRoutes from '../../../../constants/pageRoutes';
import { logOut } from '../../../../features/auth/authSlice';

const companyTypes = [
  { value: 'sole proprietor', label: 'Sole Proprietor' },
  { value: 'partnership', label: 'Partnership' },
  { value: ' private limited company', label: 'Private Limited Company' },
  { value: 'company limited by guarantee', label: 'Company Limited by Guarantee' },
  { value: 'NGO', label: 'NGO' },
];

const categories = [
  {
    value: 1,
    label: 'Agriculture',
  },
  {
    value: 2,
    label: 'Education',
  },
  {
    value: 3,
    label: 'Energy',
  },
  {
    value: 4,
    label: 'Financial Services',
  },
  {
    value: 5,
    label: 'Healthcare',
  },
  {
    value: 6,
    label: 'Manufacturing',
  },
  {
    value: 7,
    label: 'Urban Planning',
  },
  {
    value: 8,
    label: 'Waste Management',
  },
  {
    value: 9,
    label: 'Limited by Guarantee',
  },
  {
    value: 10,
    label: 'Textile/Clothing',
  },
  {
    value: 11,
    label: 'ICT',
  },
  {
    value: 12,
    label: 'Transport',
  },
  {
    value: 13,
    label: 'Hospitality',
  },
  {
    value: 14,
    label: 'Utilities(water, gas, electricity)',
  },
  {
    value: 15,
    label: 'Media',
  },
  {
    value: 16,
    label: 'Mining',
  },
  {
    value: 17,
    label: 'Professional services',
  },
  {
    value: 18,
    label: 'Other',
  },
];

const BusinessProfile = () => {
  const { data: user } = useGetUserQuery();
  const [createBusiness, { isLoading }] = useCreateBusinessMutation();
  const {
    data: userDetails,
    error: userDetailsError,
    isLoading: userDetailsLoading,
    isSuccess,
  } = useGetUserDetailsQuery(user.id);
  const username = user.name.split(' ').slice(0, -1).join(' ');
  const steps = ['Create Account', 'Set up'];
  const isActive = 'Set up';
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<Profile>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmitHandler = async (data) => {
    const inputData = {
      created_by: user.id,
      updated_by: user.id,
      ...data,
    };
    try {
      await createBusiness(inputData).unwrap();
      navigate(`/${pageRoutes.DASHBOARD}/${pageRoutes.WAIT_LIST}`);
    } catch (e) {
      toast.error('Something went wrong!');
    }
  };

  const buttonContent = isLoading ? (
    <div className={'container-scaling'}>
      <div className="scaling-dots">
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  ) : (
    <>
      <span>Finish</span>
      <BsArrowRight />
    </>
  );

  if (userDetailsLoading) return <div className={'whirl_container whirl'} />;
  else if (userDetailsError) {
    console.log(userDetailsError);
    // @ts-ignore
    if (userDetailsError.status === 401) dispatch(logOut());
  } else if (isSuccess) {
    if (userDetails.companies.length > 0) {
      console.log(userDetails);
      return <Navigate to={`/${pageRoutes.DASHBOARD}/${pageRoutes.WAIT_LIST}`} replace />;
    } else {
      return (
        <>
          <main className={styles.container}>
            <section style={{ backgroundImage: `url(${images.africaLight})` }} className={styles.welcome}>
              <img className={styles.logo} src={images.mkWhite} alt="Melanin Kapital Logo" />
              <p className={styles.b_prof}>Business Profile</p>
              <p className={styles.thanks}>Thank you for signing up!</p>
              <h3>Great job {username} We would be happy If you completed your business profile</h3>
              <h6>
                We will try and create a suitable account Based on your needs and goals. Either as An entrepreneur,
                lender or investor we got You covered
              </h6>
            </section>
            <section className={styles.main_content}>
              <div className={styles.options}>
                <img className={styles.logo_mobile} src={images.mkGreen} alt="Melanin Kapital Logo" />
                {steps.map((elem, index) => (
                  <div className={`${styles.card} ${isActive === elem ? styles.card_active : ''}`} key={elem}>
                    <div className={styles.check}>{isActive === elem ? index + 1 : <AiOutlineCheck />}</div>
                    <p>{elem}</p>
                  </div>
                ))}
              </div>
              <div className={styles.content}>
                <h1>Great job {username} We would be happy If you completed your business profile</h1>
                <Form className={styles.inputs} id={'create-business'} onSubmit={handleSubmit(onSubmitHandler)}>
                  <IContainer rows={3}>
                    <Wrap>
                      <Input placeholder={'Company name'} type={'text'} autoFocus {...register('name')} />
                      {errors.name?.message && <small>{errors.name?.message}</small>}
                    </Wrap>
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Wrap>
                            <Select
                              value={companyTypes.find((c: any) => c.value === value)}
                              name={name}
                              options={companyTypes}
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                }),
                                control: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                  height: '5.4rem',
                                }),
                              }}
                              isSearchable
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cdd5f7',
                                  primary: '#FFAB00',
                                  neutral20: '#707070',
                                },
                              })}
                              placeholder={'Select Type of Company'}
                            />
                            {errors.type_of_company?.message && <small>{errors.type_of_company?.message}</small>}
                          </Wrap>
                        );
                      }}
                      name={'type_of_company'}
                      rules={{
                        required: true,
                      }}
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Wrap>
                            <Select
                              value={categories.find((c: any) => c.value === value)}
                              name={name}
                              options={categories}
                              onChange={(selectedOption: any) => {
                                onChange(selectedOption.value);
                              }}
                              styles={{
                                container: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                }),
                                control: (base) => ({
                                  ...base,
                                  fontSize: '1.6rem',
                                  height: '5.4rem',
                                }),
                              }}
                              isSearchable
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#cdd5f7',
                                  primary: '#FFAB00',
                                  neutral20: '#707070',
                                },
                              })}
                              placeholder={'Select Business Sector'}
                            />
                            {errors.category_id?.message && <small>{errors.category_id?.message}</small>}
                          </Wrap>
                        );
                      }}
                      name={'category_id'}
                      rules={{
                        required: true,
                      }}
                    />
                    <Wrap>
                      <Input placeholder={'Office location'} type={'text'} {...register('office_location')} />
                      {errors.office_location?.message && <small>{errors.office_location?.message}</small>}
                    </Wrap>
                    <Input
                      placeholder={'Company website(if available)'}
                      type={'text'}
                      {...register('company_website')}
                    />
                    <Wrap>
                      <Input
                        placeholder={'Company registered phone number'}
                        type={'tel'}
                        {...register('office_phone')}
                      />
                      {errors.office_phone?.message && <small>{errors.office_phone?.message}</small>}
                    </Wrap>
                  </IContainer>
                  <Wrap>
                    <Input
                      placeholder={'Company registered email address'}
                      type={'email'}
                      {...register('office_email')}
                    />
                    {errors.office_email?.message && <small>{errors.office_email?.message}</small>}
                  </Wrap>
                  <Wrap className={'t_area'}>
                    <TArea
                      placeholder={'Brief history about your business'}
                      rows={10}
                      {...register('business_history')}
                    />
                    {errors.business_history?.message && <small>{errors.business_history?.message}</small>}
                  </Wrap>
                  <Socials>
                    <ImLinkedin /> <input placeholder={'LinkedIn link'} type={'text'} {...register('linkedin')} />
                  </Socials>
                  <Socials>
                    <BsTwitter /> <input placeholder={'Twitter link'} type={'text'} {...register('twitter')} />
                  </Socials>
                  <Socials>
                    <ImFacebook2 /> <input type={'text'} placeholder={'Facebook link'} {...register('facebook')} />
                  </Socials>
                </Form>
                <motion.button
                  type={'submit'}
                  form={'create-business'}
                  disabled={!isDirty || !isValid || isLoading}
                  whileHover={{ backgroundColor: '#014e35' }}
                  whileTap={{ scale: 0.98 }}
                  className={styles.sign_in_btn}>
                  {buttonContent}
                </motion.button>
              </div>
            </section>
          </main>
          <ToastContainer autoClose={1000} />
        </>
      );
    }
  }
};

export default BusinessProfile;
