import styled from 'styled-components';

export const Wrapper = styled.section`
  text-align: center;
  font-weight: 500;
  display: flex;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/waitlist%2Fconfetti-new%402x.png?alt=media&token=d3b14a2c-08d2-426a-8415-efbd2b4efa21');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  align-items: center;
  flex-flow: column;
  position: relative;
  padding-bottom: 3rem;

  @media screen and (max-width: 768px) {
    transform: scale(0.7);
  }
`;

export const Title = styled.h1`
  color: var(--color-blue);
  font-size: 4.5rem;
  letter-spacing: 0.5px;
`;

export const HeadText = styled.h3`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 2.6rem;
  font-weight: normal;
  margin-bottom: 4rem;
  max-width: 80%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Handshake = styled.img`
  width: 30rem;
  height: auto;

  @media screen and (max-width: 768px) {
    margin-top: -10rem;
    width: 20rem;
  }
`;

export const Logo = styled.img`
  width: auto;
  margin: 0 auto;
  height: 4rem;
  display: block;
  object-fit: contain;
`;
