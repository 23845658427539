import { css } from 'styled-components';

const H3 = css`
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.72px;
`;

const BODY1 = css`
  font-size: 1.6rem;
  letter-spacing: 0.48px;
`;

const BODY2 = css`
  font-size: 1.44rem;
  font-weight: 500;
  letter-spacing: 0.43px;
`;

const BODY3 = css`
  font-size: 1.36rem;
  letter-spacing: 0.41px;
`;

const BODY4 = css`
  font-size: 1.2rem;
  letter-spacing: 0.36px;
`;

const BODY5 = css`
  font-size: 1.92rem;
  letter-spacing: 0.58px;
`;

const typography = {
  H3,
  BODY1,
  BODY2,
  BODY3,
  BODY4,
  BODY5
};

export default typography;
