import {
  WaitListModal,
} from './modals';


interface IModalTypes {
  WAIT_LIST_MODAL: string;
}

export type TModalType = string;

export const MODAL_TYPES: IModalTypes = {
  WAIT_LIST_MODAL: 'WAIT_LIST_MODAL',
};

export const MODALS_MAPPING = {
  [MODAL_TYPES.WAIT_LIST_MODAL]: WaitListModal,
};
