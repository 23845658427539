import React from 'react';
import { Handshake, HeadText, Logo, Text, Title, Wrapper } from './styles';
import Modal from 'react-modal';
import images from '../../../../constants';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    zIndex: 100,
    border: 'none',
    width: '63%',
    borderRadius: '20px',
  },
};

function WaitListModal({ onClose, name }) {
  return (
    <Modal onRequestClose={onClose} isOpen={true} style={customStyles} ariaHideApp={false} overlayClassName="Overlay-2">
      <Wrapper>
        <Handshake
          src={`https://firebasestorage.googleapis.com/v0/b/nectar-f5ee3.appspot.com/o/waitlist%2FMask%20Group%20195%402x.png?alt=media&token=b440f390-28b8-447e-922c-1c9ab45fd13e`}
          alt="Handshake"
        />
        <Title>Welcome on board {name}!</Title>
        <HeadText>You have been added to our wait list</HeadText>
        <Text>
          Thank you for your interest in joining the Tuungane Program Powered By <Logo src={images.logo} /> We have sent
          you an email with details concerning the launch of the event.
        </Text>
      </Wrapper>
    </Modal>
  );
}

export default WaitListModal;
