enum pageRoutes {
  SIGN_IN = 'sign_in',
  SIGN_UP = 'sign_up',
  DASHBOARD = 'tuungane',
  WAIT_LIST = 'wait-list',
  PROFILE = 'fill-business-profile',

  NEW_PASSWORD = 'password-new',
}

export default pageRoutes;
