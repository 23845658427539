import React from 'react';
import styles from './Auth.module.scss';
import images from '../../constants';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import pageRoutes from '../../constants/pageRoutes';
import { motion } from 'framer-motion';

const AuthLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isNewPassword = location.pathname === `/${pageRoutes.NEW_PASSWORD}`;
  let brief;
  let route;
  let btnContent;

  switch (location.pathname) {
    case `/${pageRoutes.NEW_PASSWORD}`:
      brief = 'Log in to your account';
      route = `/${pageRoutes.SIGN_IN}`;
      btnContent = 'Log in';
      break;
    case `/${pageRoutes.SIGN_IN}`:
      brief = "Don't have an account?";
      route = `/${pageRoutes.SIGN_UP}`;
      btnContent = 'Sign up';
      break;
    default:
      brief = 'Already have an account?';
      route = `/${pageRoutes.SIGN_IN}`;
      btnContent = 'Sign in';
  }

  const header = isNewPassword ? (
    <h3>Successfully changed your old password?</h3>
  ) : (
    <h3>
      Start your journey to becoming <br /> credit-ready
    </h3>
  );

  const handleClick = () => {
    navigate(route);
  };

  return (
    <section className={styles.signin}>
      <div style={{ backgroundImage: `url(${images.nairobi})` }} className={styles.hero}>
        <img src={images.mkGreen} alt="M kapital logo" />
        <div className={styles.hero__capsule}>
          <div className={styles.container}>
            {header}
            <p>{brief}</p>
            <motion.button
              onClick={handleClick}
              whileHover={{ scale: 1.05, backgroundColor: 'white', color: 'black' }}
              whileTap={{ scale: 0.9 }}>
              {btnContent}
            </motion.button>
          </div>
        </div>
      </div>
      <Outlet />
    </section>
  );
};
export default AuthLayout;
