import styled from 'styled-components';

export const IContainer = styled.div<{ rows: number }>`
  display: grid;
  grid: repeat(${(props) => props.rows}, 1fr) / 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 2.4rem;
  margin-bottom: 2.4rem;

  @media screen and (max-width: 768px) {
    grid: 1fr/1fr;
  }
`;

export const Input = styled.input`
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  border: 1px solid #707070;
  padding: 1.6rem;
  width: 100%;

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const TArea = styled.textarea`
  outline: none;
  border: 1px solid #707070;
  border-radius: 5px;
  width: 100%;
  padding: 1.6rem 1.6rem 0;
  max-height: 8rem;

  &:focus {
    border-color: var(--color-primary);
  }
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  column-gap: 2rem;
  margin-bottom: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }

  input {
    flex: 1;
    border-radius: 5px;
    padding: 1.6rem;
    background-color: transparent;
    outline: none;
    border: 1px solid #707070;

    &:focus {
      border-color: var(--color-primary);
    }
  }
`;

export const Wrap = styled.div`
  position: relative;

  small {
    display: block;
    color: red;
    font-size: 1.4rem;
    position: absolute;
    bottom: -1.6rem;
    text-align: left;
  }

  &.t_area {
    margin: 2.4rem 0;
  }
`;
export const Form = styled.form`
  width: 100%;
  padding: 2rem;
`;
