import React from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logOut, selectCurrentToken } from '../features/auth/authSlice';
import { useGetUserDetailsQuery, useGetUserQuery } from '../features/user/userApiSlice';
import pageRoutes from '../constants/pageRoutes';

const DashboardLayout = () => {
  const navigate = useNavigate();
  const token = useAppSelector(selectCurrentToken);
  const dispatch = useAppDispatch();
  const { data, error, isLoading } = useGetUserQuery();

  let content;
  if (isLoading) {
    content = <div className={'whirl_container whirl'} />;
  } else if (error) {
    console.log(error);
    // @ts-ignore
    if (error.status === 401) dispatch(logOut());
  } else if (data) {
    content = <Outlet />;
  }

  return token ? <>{content}</> : <Navigate to="/" />;
};

export default DashboardLayout;
