import React from 'react';
import styles from '../Auth.module.scss';
import images from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { schema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISignUpData } from '../../../types/user';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import pageRoutes from '../../../constants/pageRoutes';
import { useLoginMutation, useRegisterUserMutation } from '../../../features/auth/authApiSlice';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../../styles/loaders.css';
import { setAccessToken } from '../../../features/auth/authSlice';
import { useAppDispatch } from '../../../app/hooks';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const [login, { isLoading: loading }] = useLoginMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<ISignUpData>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmitHandler = async (data: ISignUpData) => {
    const userInput = {
      name: `${data.firstName} ${data.lastName}`,
      password: data.password,
      email: data.email,
      program: 1,
      role_type: 'ent',
    };
    const loginData = {
      email: data.email,
      password: data.password,
    };
    try {
      await registerUser({ ...userInput }).unwrap();
      const token = await login(loginData).unwrap();
      dispatch(setAccessToken({ ...token }));
      navigate(`/${pageRoutes.DASHBOARD}/${pageRoutes.PROFILE}`);
    } catch (err: any) {
      if (err.data.response.errors.email.length > 0) {
        toast.error('You have already signed up for the program!');
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  const buttonContent =
    isLoading || loading ? (
      <div className={'container-scaling'}>
        <div className="scaling-dots">
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : (
      'Continue'
    );

  return (
    <motion.div className={styles.main} initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }}>
      <img className={styles.logo} src={images.logo} alt="Melanin Kapital logo" />
      <h2>
        Let's get started on your
        <br />
        Sign up process
      </h2>
      <p className={styles.welcome}>Welcome back! Kindly provide the following to sign up</p>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div className={styles.names}>
          <div className={styles.input_container}>
            <input className={styles.text_input} {...register('firstName')} placeholder={'First name'} />
            {errors.firstName?.message && <small>{errors.firstName?.message}</small>}
          </div>
          <div className={styles.input_container}>
            <input className={styles.text_input} {...register('lastName')} placeholder={'Last name'} />
            {errors.lastName?.message && <small>{errors.lastName?.message}</small>}
          </div>
        </div>
        <div className={styles.input_container}>
          <input className={styles.text_input} type={'email'} {...register('email')} placeholder={'Email'} />
          {errors.email?.message && <small>{errors.email?.message}</small>}
        </div>
        <div className={styles.input_container}>
          <input className={styles.text_input} type={'password'} {...register('password')} placeholder={'Password'} />
          {errors.password?.message && <small>{errors.password?.message}</small>}
        </div>
        <div className={styles.input_container}>
          <input
            className={styles.text_input}
            type={'password'}
            {...register('confirmPassword')}
            placeholder={'Confirm password'}
          />
          {errors.confirmPassword?.message && <small>{errors.confirmPassword?.message}</small>}
        </div>
        <p className={styles.policies}>
          By continuing you agree to the{' '}
          <a
            target={'_blank'}
            href={
              'https://melaninkapital.sharepoint.com/:b:/s/MelaninKapitalDataroom/Eawnci9iAWtGhzxEOjZhQWMBO4wlyGikwrr_FgS57GOZgQ?e=KCaRDC'
            }>
            Terms & Conditions{' '}
          </a>
          of Melanin Kapital.
        </p>
        <motion.button
          type={'submit'}
          disabled={!isDirty || !isValid || isLoading}
          whileHover={{ backgroundColor: '#014e35' }}
          whileTap={{ scale: 0.98 }}
          className={styles.sign_in_btn}>
          {buttonContent}
        </motion.button>
      </form>
      <p className={styles.option}>
        Already have an account? <span onClick={() => navigate(`/${pageRoutes.SIGN_IN}`)}>Sign In</span>
      </p>
      <ToastContainer autoClose={1000} />
    </motion.div>
  );
};

export default Register;
